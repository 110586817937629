
import React from 'react';
import Preloader from "./Preloader/Preloader";
import Optin from "./Optin/Optin";
import ContactBar from "./contactBar";


export default function ComingSoon(props) {
    return (
        <div>
            <div className="container">
                <h1>
                    This webpage is
                    <br />
                    under construction
                </h1>
                <div>
                    Connect with us:
                </div>
                <ContactBar/>
                <Preloader />
            </div>
        </div>
    );
}
