import React from 'react';

import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


// import bootstrap from 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery';
import 'popper.js';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './index.css';

import App from './App';
import Home from "./pages/home";
import About from "./pages/about";
import BuildingDesign from "./pages/buildingDesign";
import Architectural from "./pages/architectural";
import Structural from "./pages/structural";
import Mep from "./pages/mep";
import ThreeD from "./pages/3d";
import Interior from "./pages/interior";
import QuantitySurvey from "./pages/quantitySurvey";
import RealEstate from "./pages/realEstate";
import Portfolio from "./pages/portfolio";
import Marketplace from "./pages/marketplace";
import Tools from "./pages/tools";
import BuildingCalculator from "./pages/buildingCalculator";
import MortgageCalculator from "./pages/mortgageCalculator";
import Blog from "./pages/blog";
import News from "./pages/news";
import Faq from "./pages/faq";
import Register from "./pages/register";
import Login from "./pages/login";
import User from "./pages/user";
import Contact from "./pages/contact";
import Accessibility from "./pages/accessibility";
import PrivacyPolicy from "./pages/privacy";
import Terms from "./pages/terms";
import NoPage from "./pages/404";
import Test from "./pages/test";
import MainHeader from './components/mainHeader';

// import './interceptors/axios';

import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <MainHeader/>
    <Routes>
      <Route path="/" element={<App />}>
        <Route exact path="" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="building-design/" element={<BuildingDesign />}>
          <Route path="architectural" element={<Architectural />} />
          <Route path="structural" element={<Structural />} />
          <Route path="mep" element={<Mep />} />
          <Route path="3d" element={<ThreeD />} />
          <Route path="interior" element={<Interior />} />
          <Route path="quantity-survey" element={<QuantitySurvey />} />
        </Route>
        <Route path="real-estate" element={<RealEstate />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="marketplace" element={<Marketplace />} />
        <Route path="tools/" element={<Tools />}>
          <Route path="building-calculator" element={<BuildingCalculator />} />
          <Route path="mortgage-calculator" element={<MortgageCalculator />} />
        </Route>
        <Route path="blog" element={<Blog />} />
        <Route path="news" element={<News />} />
        <Route path="faq" element={<Faq />} />
        <Route path="contact" element={<Contact />} />
        <Route path="register" element={<Register />} />
        <Route path="login" element={<Login />} />
        <Route path="user" element={<User />} />
        <Route path="accessibility" element={<Accessibility />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="test" element={<Test />} />
        <Route path="test/:test" element={<Test />} />
        <Route
          path="*"
          element={<NoPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




//         <Route path="developments/:development_slug" element={<Development />} />
//         <Route path="developments/:development_slug/buildings/:building_slug" element={<Building />} />
//         <Route path="developments/:development_slug/lands/:land_slug" element={<Land />} />
//         <Route path="order" element={<Order />} />
//         <Route path="order/confirmed" element={<OrderConfirmed />} />
//         <Route path="test" element={<Test />} />
//         <Route path="test/:test" element={<Test />} />
//         <Route
//           path="*"
//           element={<NoPage />} />