
import React from 'react';

import ComingSoon from '../components/comingSoon';

class Accessibility extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <article>
                <ComingSoon />
            </article>

        );
    }
}

export default Accessibility;

