
import React from 'react';
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, EffectCube, EffectFade, Navigation, Pagination, } from 'swiper/modules';
import "swiper/css";
import "swiper/css/bundle";

import test from "../images/pages/home/test.png"
import test1 from "../images/pages/home/test1.png"
import test2 from "../images/pages/home/test2.jpg"
import architectural from "../images/pages/home/architectural.jpeg"
import structural from "../images/pages/home/structural.jpg"
import interior from "../images/pages/home/interior.jpeg"
import quantitySurvey from "../images/pages/home/quantity-survey.jpg"
import threeDDesign from "../images/services/designs/buildings/residential/detached/triplexes/03/BR-DT-03-Day-scene-1.png"


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselData: [
                // Replace this with your actual data from state or API response
                {
                    id: 1,
                    title: 'About us',
                    subtitle: 'Arc n Des',
                    description: 'Your one-stop center for all things building.',
                    image: test,
                    link: '/about',
                },
                {
                    id: 2,
                    title: 'Building Design Services',
                    description: 'We offer a wide range of building design services',
                    nestedSlides: [
                        {
                            title: 'Building Design Services',
                            subtitle: 'Architectural Design',
                            description: "We don't just design buildings, we design experiences. Our architects are storytellers, weaving functionality with aesthetics to create spaces that inspire and delight",
                            image: architectural,
                            link: '/building-design/architectural',
                        },
                        {
                            title: 'Building Design Services',
                            subtitle: 'Structural Design',
                            description: '...',
                            image: structural,
                            link: '/building-design/structural',
                        },
                        {
                            title: 'Building Design Services',
                            subtitle: 'MEP Design',
                            description: '...',
                            image: test2,
                            link: '/building-design/mep',
                        },
                        {
                            title: 'Building Design Services',
                            subtitle: 'Interior Design',
                            description: '...',
                            image: interior,
                            link: '/building-design/interior',
                        },
                        {
                            title: 'Building Design Services',
                            subtitle: '3D Design',
                            description: '...',
                            image: threeDDesign,
                            link: '/building-design/3d',
                        },
                        {
                            title: 'Building Design Services',
                            subtitle: 'Quantity Survey',
                            description: '...',
                            image: quantitySurvey,
                            link: '/building-design/quantity-survey',
                        },
                        // Add more nested slides here
                    ],
                },
                {
                    id: 3,
                    title: 'Real Estate Services',
                    description: 'We offer a wide range of real estate services',
                    image: test1,
                    nestedSlides: [
                        {
                            title: 'Development',
                            description: '...',
                            image: test2,
                            link: '/architectural-design',
                        },
                        {
                            title: 'Property Listings',
                            description: '...',
                            image: test2,
                            link: '/architectural-design',
                        },
                        {
                            title: 'Property Valuation',
                            description: '...',
                            image: test2,
                            link: '/architectural-design',
                        },
                        // Add more nested slides here
                    ],
                },
                {
                    id: 4,
                    title: 'Information',
                    description: 'We offer a wide range of useful information',
                    image: test1,
                    nestedSlides: [
                        {
                            title: 'Marketplace',
                            description: 'Where building materials, equipments, and other things related to building development are sold.',
                            image: test2,
                            link: '/architectural-design',
                        },
                        // Add more nested slides here
                    ],
                },
                {
                    id: 5,
                    title: 'Tools',
                    description: 'We offer a wide range of tools to enhance productivity in the build environment workspace',
                    image: test1,
                    nestedSlides: [
                        {
                            title: 'Building Cost Calculator',
                            description: '...',
                            image: test2,
                            link: '/architectural-design',
                        },
                        // Add more nested slides here
                    ],
                },
            ],
        };

    }

    renderSlideContent(slideData) {
        return (
            <div className="slide-content">
                <img src={slideData.image} alt={slideData.title} class="slide-img" />
                <div class="slide-content-body">
                    <h5 class="slide-title">{slideData.title}</h5>
                    <h1 class="slide-subtitle">{slideData.subtitle}</h1>
                    <p>{slideData.description}</p>
                    {slideData.link && (
                        <a href={slideData.link} className="slide-content-link btn">
                            Learn More
                        </a>
                    )}
                </div>
            </div>
        );
    }

    renderNestedCarousel(nestedSlides) {
        return (
            <Swiper
                direction={'vertical'}
                className="nested-carousel mySwiper swiper-v"
                centeredSlides={true}
                effect={'cube'}
                grabCursor={true}
                lazy={true}
                loop={true}
                navigation={true}
                slidesPerView={1}
                spaceBetween={50}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                    type: 'progressbar',
                }}
                modules={[
                    Autoplay,
                    EffectCoverflow,
                    EffectFade,
                    Navigation,
                    Pagination,
                ]}
            >
                {nestedSlides.map((slide) => (
                    <SwiperSlide key={slide.id}>
                        {this.renderSlideContent(slide)}
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }

    renderCarousel() {
        return (
            <div>
                <Swiper
                    centeredSlides={true}
                    className="mySwiper swiper-h"
                    effect={'cube'}
                    grabCursor={true}
                    lazy={true}
                    loop={true}
                    navigation={true}
                    slidesPerView={1}
                    spaceBetween={50}
                    autoplay={{
                        delay: 14500,
                        disableOnInteraction: false,
                    }}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        type: 'progressbar',
                    }}
                    modules={[
                        Autoplay,
                        EffectCoverflow,
                        EffectFade,
                        Navigation,
                        Pagination,
                    ]}
                >
                    {this.state.carouselData.map((slideData) => (
                        <SwiperSlide key={slideData.id}>
                            {slideData.nestedSlides ? (
                                this.renderNestedCarousel(slideData.nestedSlides)
                            ) : (
                                this.renderSlideContent(slideData)
                            )}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        );
    }

    render() {
        return (
            <article id='home-page'>
                {this.renderCarousel()}
            </article>

        );
    }
}

export default Home;

