
import React from 'react';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';


export default function ContactBar(props) {
    return (
        <div>
            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#3b5998' }} href='https://web.facebook.com/arcndes'>
                <MDBIcon fab icon='facebook-f' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#333333' }} href='https://github.com/arc-n-des'>
                <MDBIcon fab icon='github' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#ac2bac' }} href='https://www.instagram.com/ands_ng/'>
                <MDBIcon fab icon='instagram' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#0082ca' }} href='https://www.linkedin.com/company/arcndes'>
                <MDBIcon fab icon='linkedin-in' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#25d366' }} href='https://wa.me/2347034328269'>
                <MDBIcon fab icon='whatsapp' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#ed302f' }} href='https://www.youtube.com/@ands_ng'>
                <MDBIcon fab icon='youtube' />
            </MDBBtn>

            {/*

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#dd4b39' }} href='#'>
                <MDBIcon fab icon='google' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#55acee' }} href='#'>
                <MDBIcon fab icon='twitter' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#c61118' }} href='#'>
                <MDBIcon fab icon='pinterest' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#4c75a3' }} href='#'>
                <MDBIcon fab icon='vk' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#ffac44' }} href='#'>
                <MDBIcon fab icon='stack-overflow' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#481449' }} href='#'>
                <MDBIcon fab icon='slack-hash' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#ec4a89' }} href='#'>
                <MDBIcon fab icon='dribbble' />
            </MDBBtn>

            <MDBBtn className='m-1' size='sm' floating style={{ backgroundColor: '#ff4500' }} href='#'>
                <MDBIcon fab icon='reddit-alien' />
            </MDBBtn> */}
        </div>
    );
}
