import './App.css';
import React from 'react';
import ReactDOM from "react-dom";
import { Outlet } from 'react-router-dom'

import Navbar from "./components/navBar"
import MainHeader from "./components/mainHeader"


function App() {
  return (

    <main id="App" className="App">
      <Outlet />
    </main>
  );
}

export default App;
