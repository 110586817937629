
import React from 'react';

import Navbar from "../components/navBar"

import test from "../images/pages/home/test.png"
import test1 from "../images/pages/home/test1.png"
import test2 from "../images/pages/home/test2.jpg"


class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselData: [
                // Replace this with your actual data from state or API response
                {
                    id: 1,
                    title: 'About AnDs',
                    description: 'A brief introduction to Arc n Des services limited',
                    image: test,
                    link: '/about-us',
                },
                {
                    id: 2,
                    title: 'Building Design Services',
                    description: 'We offer a wide range of building design services',
                    image: test1,
                    nestedSlides: [
                        {
                            title: 'Architectural Design',
                            description: '...',
                            image: test2,
                            link: '/architectural-design',
                        },
                        // Add more nested slides here
                    ],
                },
                // Add data for Real Estate services, Information services, and Apps
            ],
        };
    }


    render() {
        return (
            <article id='home-page'>
              <Navbar/>

            </article>

        );
    }
}

export default Test;

