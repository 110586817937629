
import React, { useState, useRef } from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import Cookies from 'js-cookie';


import ContactBar from "./contactBar";

import logo from "../images/AnDs-logo.jpg"

// import { AuthUserContext } from '../contexts/AuthUserContext';



export default function MainHeader(props) {
    // const [state, dispatch] = useContext(Context)
    // const { activeClassName, setActiveClassName } = useState('underline')

    const elementRef = useRef(null);

    const removeExternalClass = () => {
      if (elementRef.current) {
        elementRef.current.classList.remove('show');
      }
    };

    const user = JSON.parse(localStorage.getItem('user'));

    const logOut = () => {
        axios
            .get(`http://127.0.0.1:8000/logout/`)
            .then((res) => {
                if (res.status === 200) {
                    Cookies.remove('access_token')
                    Cookies.remove('refresh_token')
                    Cookies.remove('csrftoken')
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            });
    }

    const loginRegister = <li className="nav-item">
        <span className="nav-link">
            <NavLink to="login" >
                Login
            </NavLink> / <NavLink to="register" >
                Register
            </NavLink>
        </span>
    </li>

    const profileLogout = <li className="nav-item">
        <div className="nav-link">
            <NavLink to="user" >
                User profile
            </NavLink>
        </div>

        {/* Don't use <a></a> tag for the logout because they cause an unexpected reactions*/}
        <div
            className="nav-link"
            onClick={logOut}>
                Logout
        </div>
    </li>


    return (
        <header>
            <nav className="navbar navbar-expand-lg sticky-top bg-light">
                <div className="container-fluid">
                    <div className="navbar-brand">
                        <NavLink onClick={removeExternalClass}
                            to="/">
                            <img src={logo} alt="Benno Logo" width="50" className="d-inline-block align-text-top" />
                        </NavLink>
                    </div>
                    <form className="d-flex navbar-form" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                        {/* Put a submit button that appears only when the search input has been clicked */}
                    </form>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div ref={elementRef} className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav">

                            {/* About us */}
                            <li className="nav-item">
                                <NavLink onClick={removeExternalClass}
                                    to="/about"
                                    className="nav-link"
                                >
                                    About Us
                                </NavLink>
                            </li>

                            {/* Services */}
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <NavLink onClick={removeExternalClass}
                                            to="/building-design"
                                            className="nav-link dropdown-item"
                                        >
                                            Building Design
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={removeExternalClass}
                                            to="/real-estate"
                                            className="nav-link dropdown-item"
                                        >
                                            Real Estate
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            {/* Portfolio */}
                            <li className="nav-item">
                                <NavLink onClick={removeExternalClass}
                                    to="/portfolio"
                                    className="nav-link"
                                >
                                    Portfolio
                                </NavLink>
                            </li>

                            {/* Marketplace */}
                            <li className="nav-item">
                                <NavLink onClick={removeExternalClass}
                                    to="/marketplace"
                                    className="nav-link"
                                >
                                    Marketplace
                                </NavLink>
                            </li>

                            {/* Tools */}
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Tools
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <NavLink onClick={removeExternalClass}
                                            to="/tools/building-calculator"
                                            className="nav-link dropdown-item"
                                        >
                                            Building Calculator
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={removeExternalClass}
                                            to="/tools/mortgage-calculator"
                                            className="nav-link dropdown-item"
                                        >
                                            Mortgage Calculator
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            {/* Info */}
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Info
                                </a>
                                <ul class="dropdown-menu">
                                    <li>
                                        <NavLink onClick={removeExternalClass}
                                            to="/blog"
                                            className="nav-link dropdown-item"
                                        >
                                            Blog
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={removeExternalClass}
                                            to="/news"
                                            className="nav-link dropdown-item"
                                        >
                                            News
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink onClick={removeExternalClass}
                                            to="/faq"
                                            className="nav-link dropdown-item"
                                        >
                                            FAQ
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>

                            {/* Contact */}
                            <li className="nav-item">
                                <NavLink onClick={removeExternalClass}
                                    to="contact"
                                    className="nav-link"
                                >
                                    Contact
                                </NavLink>
                            </li>

                            {/* User Account */}
                            {user ? profileLogout
                                : loginRegister}

                            {/* Social Media Links */}
                            <li className="nav-item">
                                <div className="nav-link">
                                    <ContactBar/>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

        </header>
    );
}


//